// popups

var menuToggles, menuPopup;

menuToggles = document.querySelectorAll('.toggle-menu');
menuPopup = document.querySelector('#menu');

if (menuToggles) {
  Array.prototype.forEach.call(menuToggles, function(el, i) {
    return el.addEventListener('click', function(e) {
      return menuPopup.classList.toggle('active');
    });
  });
}

// magic grid gallery

var gallery;

gallery = document.querySelector('.gallery__grid');

if (gallery) {
  let magicGrid = new MagicGrid({
    container: ".gallery__grid",
    static: true,
    animate: true,
    gutter: 0,
    useMin: true,
    maxColumns: 3,
    animate: true,
  });

  magicGrid.listen();
}



//////

import "./components/smooth-scroll.min";

// smooth-scroll
var scroll = new SmoothScroll('a[href*="#"]', {
  offset: 0
} );
